/* navbar link glow */
.nav-link.active { 
  /* color: #8082b1 !important;  */
  text-shadow: 1px 1px 2px black, 0 0 25px #795589, 0 0 5px #530ebb;
}

/* about nina page */
.about-question {
  color: purple;
  font-weight: bold;
}

/* light a candle icon */
.fixed-bottom {
  pointer-events: none;
}

.light-candle-button {
  pointer-events: auto;
}

/* about-site */
.home-link-list {
  overflow: hidden;
  margin: 1em;
  text-align: center;
}

.home-link-list ul {
  list-style: none;
  padding: 0;
  margin-left: -4px;
}

.home-link-list ul li {
  display: inline;
  white-space: nowrap;
}

.home-link-list li:not(:first-child)::before {
  content: "|";
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.home-link-list ul li {
  float: none;
  display: inline-block;
}

/* send message / light a candle modal */
.modal-backdrop {
  opacity: .8 !important;
}

.bottom-spacer {
  height: 80px;
}

.translation-header {
  display: block;
  text-align: center;
  border-top: 1px dotted white;
  border-bottom: 1px dotted white;
  /* text-transform: uppercase; */
  /* border-style: solid; */
}